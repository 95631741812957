var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":_vm.refName,"size":"xl","headerText":_vm.$t('Questions.data'),"headerIcon":_vm.headerIcon}},[_c('div',{staticClass:"row"},[(_vm.question.questionMediaPath)?_c('div',{staticClass:"col-md-12",staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{staticClass:"mb-2",attrs:{"src":_vm.fullPathFileFromServer(
            _vm.question.questionMediaPath,
            _vm.question.defaultImg
          ),"onerror":("this.src='" + (_vm.question.defaultImg) + "'")}})]):_vm._e(),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.question.questionTitle,"title":_vm.$t('Questions.title'),"imgName":'question.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.question.questionDescription_HTML,"title":_vm.$t('general.description'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.question.questionBankInfoData
          ? _vm.question.questionBankInfoData.questionBankNameCurrent
          : '',"title":_vm.$t('QuestionBanks.name'),"imgName":'questionBank.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.question.questionParagraphInfoData
          ? _vm.question.questionParagraphInfoData.questionParagraphTitle
          : '',"title":_vm.$t('QuestionParagraphs.name'),"imgName":'questionParagraph.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.question.questionBankTitleInfoData
          ? _vm.question.questionBankTitleInfoData.questionBankTitleNameCurrent
          : '',"title":_vm.$t('QuestionBankTitles.name'),"imgName":'questionParagraph.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.question.questionAnswerDescription_HTML,"title":_vm.$t('Questions.answerDescription'),"imgName":'description.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.question.questionTypeNameCurrent,"title":_vm.$t('questionsTypes.name'),"imgName":'type.svg'}}),(_vm.question.questionDescription_MediaPath)?_c('div',{staticClass:"col-md-12",staticStyle:{"display":"flex","justify-content":"center"}},[_c('img',{attrs:{"src":_vm.fullPathFileFromServer(
            _vm.question.questionDescription_MediaPath,
            _vm.question.defaultImg
          ),"onerror":("this.src='" + (_vm.question.defaultImg) + "'"),"title":_vm.$t('Questions.answerDescriptionImg')}})]):_vm._e(),(_vm.question.questionTypeToken == _vm.QUESTION_TYPE.TrueFalse)?[_c('CustomCheckbox',{attrs:{"className":'col-md-12 centering mt-3',"value":_vm.question.trueFalseQuestionData.questionAnswer,"showAsInfo":true,"title":_vm.$t('Questions.rightAnswer')}})]:_vm._e(),(_vm.question.questionTypeToken == _vm.QUESTION_TYPE.Chose)?[_c('div',{staticClass:"my-card row"},[_c('span',{staticClass:"my-card-title"},[_vm._v(" "+_vm._s(_vm.$t("Questions.answers"))+" ")]),_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("Questions.answerText"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("Questions.rightAnswer"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("general.image"))+" ")])])]),_c('tbody',[(
                  _vm.question.mCQQuestionData.questionMCQAnswerData.length == 0
                )?[_c('tr',[_c('th',{attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.$t("Questions.noAnswers"))+" ")])])]:_vm._l((_vm.question.mCQQuestionData
                    .questionMCQAnswerData),function(item,indexAnswer){return _c('tr',{key:indexAnswer},[_c('td',[_vm._v(_vm._s(indexAnswer + 1))]),_c('td',[_c('CustomInput',{attrs:{"id":("table-info-" + indexAnswer + "-answerText"),"value":item.answerText,"withOutDesign":true,"isDisabled":true}})],1),_c('td',[_c('CustomCheckbox',{attrs:{"id":("table-info-" + indexAnswer + "-answerIsTrue"),"value":item.answerIsTrue,"showAsInfo":true,"centerStatus":true,"withOutTitle":true}})],1),_c('td',[(indexAnswer == 0)?_c('img',{staticClass:"full-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                          _vm.question.questionAnswerA_MediaPath,
                          _vm.question.defaultImg
                        ),"onerror":("this.src='" + (_vm.question.defaultImg) + "'")}}):_vm._e(),(indexAnswer == 1)?_c('img',{staticClass:"full-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                          _vm.question.questionAnswerB_MediaPath,
                          _vm.question.defaultImg
                        ),"onerror":("this.src='" + (_vm.question.defaultImg) + "'")}}):_vm._e(),(indexAnswer == 2)?_c('img',{staticClass:"full-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                          _vm.question.questionAnswerC_MediaPath,
                          _vm.question.defaultImg
                        ),"onerror":("this.src='" + (_vm.question.defaultImg) + "'")}}):_vm._e(),(indexAnswer == 3)?_c('img',{staticClass:"full-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                          _vm.question.questionAnswerD_MediaPath,
                          _vm.question.defaultImg
                        ),"onerror":("this.src='" + (_vm.question.defaultImg) + "'")}}):_vm._e()])])})],2)])])])]:_vm._e()],2),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();return _vm.closeBottomSheet(_vm.refName)}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }