var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomBottomSheet',{attrs:{"refName":_vm.refName,"size":"xl","headerText":_vm.$t('EducationalCourseLevelQuestions.data'),"headerIcon":_vm.headerIcon}},[_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalCourseLevelQuestion.questionDegree,"title":_vm.$t('general.degree'),"imgName":'number.svg'}}),(_vm.educationalCourseLevelQuestion.questionData)?[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalCourseLevelQuestion.questionData.questionTitle,"title":_vm.$t('general.title'),"imgName":'EducationalCourseLevelQuestions.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalCourseLevelQuestion.questionData.questionTypeNameCurrent,"title":_vm.$t('questionsTypes.name'),"imgName":'type.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalCourseLevelQuestion.questionData.questionBankInfoData
            .questionBankNameCurrent,"title":_vm.$t('QuestionBanks.name'),"imgName":'questionBank.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-12',"value":_vm.educationalCourseLevelQuestion.questionData.questionParagraphDataDB
            ? _vm.educationalCourseLevelQuestion.questionData
                .questionParagraphDataDB.questionParagraphTitle
            : '',"title":_vm.$t('QuestionParagraphs.name'),"imgName":'questionParagraph.svg'}}),(
          _vm.educationalCourseLevelQuestion.questionData.questionTypeToken ==
            _vm.QUESTION_TYPE.TrueFalse
        )?[_c('CustomCheckbox',{attrs:{"className":'col-md-12 centering mt-3',"value":_vm.educationalCourseLevelQuestion.questionData.trueFalseQuestionData
              .questionAnswer,"showAsInfo":true,"title":_vm.$t('Questions.rightAnswer')}})]:_vm._e(),(
          _vm.educationalCourseLevelQuestion.questionData.questionTypeToken ==
            _vm.QUESTION_TYPE.Chose
        )?[_c('div',{staticClass:"my-card row"},[_c('span',{staticClass:"my-card-title"},[_vm._v(" "+_vm._s(_vm.$t("Questions.answers"))+" ")]),_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("Questions.answerText"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("Questions.rightAnswer"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("general.image"))+" ")])])]),_c('tbody',[(
                    _vm.educationalCourseLevelQuestion.questionData
                      .mCQQuestionData.questionMCQAnswerData.length == 0
                  )?[_c('tr',[_c('th',{attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.$t("Questions.noAnswers"))+" ")])])]:_vm._l((_vm.educationalCourseLevelQuestion
                      .questionData.mCQQuestionData.questionMCQAnswerData),function(item,indexAnswer){return _c('tr',{key:indexAnswer},[_c('td',[_vm._v(_vm._s(indexAnswer + 1))]),_c('td',[_c('CustomInput',{attrs:{"id":("table-info-" + indexAnswer + "-answerText"),"value":item.answerText,"withOutDesign":true,"isDisabled":true}})],1),_c('td',[_c('CustomCheckbox',{attrs:{"id":("table-info-" + indexAnswer + "-answerIsTrue"),"value":item.answerIsTrue,"showAsInfo":true,"centerStatus":true,"withOutTitle":true}})],1),_c('td',[(
                          indexAnswer == 0 &&
                            _vm.educationalCourseLevelQuestion.questionData
                              .questionAnswerA_MediaPath
                        )?_c('img',{staticClass:"full-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                            _vm.educationalCourseLevelQuestion.questionData
                              .questionAnswerA_MediaPath,
                            _vm.educationalCourseLevelQuestion.defaultImg
                          ),"onerror":("this.src='" + (_vm.educationalCourseLevelQuestion.defaultImg) + "'")}}):(
                          indexAnswer == 1 &&
                            _vm.educationalCourseLevelQuestion.questionData
                              .questionAnswerB_MediaPath
                        )?_c('img',{staticClass:"full-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                            _vm.educationalCourseLevelQuestion.questionData
                              .questionAnswerB_MediaPath,
                            _vm.educationalCourseLevelQuestion.defaultImg
                          ),"onerror":("this.src='" + (_vm.educationalCourseLevelQuestion.defaultImg) + "'")}}):(
                          indexAnswer == 2 &&
                            _vm.educationalCourseLevelQuestion.questionData
                              .questionAnswerC_MediaPath
                        )?_c('img',{staticClass:"full-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                            _vm.educationalCourseLevelQuestion.questionData
                              .questionAnswerC_MediaPath,
                            _vm.educationalCourseLevelQuestion.defaultImg
                          ),"onerror":("this.src='" + (_vm.educationalCourseLevelQuestion.defaultImg) + "'")}}):(
                          indexAnswer == 3 &&
                            _vm.educationalCourseLevelQuestion.questionData
                              .questionAnswerD_MediaPath
                        )?_c('img',{staticClass:"full-img-table",attrs:{"src":_vm.fullPathFileFromServer(
                            _vm.educationalCourseLevelQuestion.questionData
                              .questionAnswerD_MediaPath,
                            _vm.educationalCourseLevelQuestion.defaultImg
                          ),"onerror":("this.src='" + (_vm.educationalCourseLevelQuestion.defaultImg) + "'")}}):_c('span',[_vm._v("--")])])])})],2)])])])]:_vm._e()]:_vm._e()],2),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();return _vm.closeBottomSheet(_vm.refName)}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }