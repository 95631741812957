var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-layout dashboard-layout"},[_c('div',{staticClass:"app-navbar dashboard-navbar"},[_c('AppNavbar',{attrs:{"layoutType":_vm.appLayouts.dashboard}})],1),_c('div',{staticClass:"app-content dashboard-content"},[(_vm.isBarsPreloaderShown)?_c('BarsPreloader',{staticClass:"dashboard",class:{
        'sidebar-opend': _vm.isSidebarOpened,
        'sidebar-closed': !_vm.isSidebarOpened,
        left: _vm.currentLanguage == _vm.appLanguages.enUS,
        right: _vm.currentLanguage == _vm.appLanguages.arEG,
      }}):_vm._e(),(_vm.isContentOverlayShown)?_c('AppOverlay'):_vm._e(),(_vm.isAppLayerShown)?_c('AppLayer',{staticClass:"dashboard",class:{
        'sidebar-opend': _vm.isSidebarOpened,
        'sidebar-closed': !_vm.isSidebarOpened,
        left: _vm.currentLanguage == _vm.appLanguages.enUS,
        right: _vm.currentLanguage == _vm.appLanguages.arEG,
      },attrs:{"currentLayerComponent":_vm.currentLayerComponent},on:{"hideAppLayer":function($event){return _vm.hideAppLayer()}}}):_vm._e(),_c('div',{staticClass:"app-content-container"},[_c('div',{staticClass:"dashboard-content-container",class:_vm.isSidebarOpened ? 'sidebar-opend' : 'sidebar-closed'},[_c('div',{staticClass:"dashboard-sidebar",class:_vm.isSidebarOpened ? 'sidebar-opend' : 'sidebar-closed'},[_c('DashboardSidebar',{staticClass:"animate__animated animate__fadeInDown"})],1),_c('router-view',{staticClass:"main-content-container",class:_vm.isSidebarOpened ? 'sidebar-opend' : 'sidebar-closed'})],1),_c('div',{staticClass:"app-footer"},[_c('AppFooter')],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }