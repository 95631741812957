var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.questionBank.questionBankImagePath,
            _vm.questionBank.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus},on:{"changeValue":function($event){_vm.questionBank.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-5',"id":(_vm.id + "-questionBankTitleToken"),"errors":_vm.errors_questionBankTitleToken,"value":_vm.questionBank.questionBankTitleToken,"options":_vm.questionBankTitleTokenOptions,"title":_vm.$t('QuestionBankTitles.select'),"imgName":'questionBankTitle.svg'},on:{"changeValue":function($event){_vm.questionBank.questionBankTitleToken = $event;
          _vm.$v.questionBank.questionBankTitleToken.$touch();
          _vm.getQuestionParagraphsDialog();}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-5',"id":(_vm.id + "-questionParagraphToken"),"value":_vm.questionBank.questionParagraphToken,"options":_vm.questionParagraphTokenOptions,"title":_vm.$t('QuestionParagraphs.select'),"imgName":'questionParagraph.svg'},on:{"changeValue":function($event){_vm.questionBank.questionParagraphToken = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-questionBankNameCurrent"),"value":_vm.questionBank.questionBankNameCurrent,"title":_vm.$t('general.name'),"imgName":'questionBank.svg'},on:{"changeValue":function($event){_vm.questionBank.questionBankNameAr = $event;
          _vm.questionBank.questionBankNameEn = $event;}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-questionBankDescriptionCurrent"),"value":_vm.questionBank.questionBankDescriptionCurrent,"title":_vm.$t('general.description'),"imgName":'description.svg'},on:{"changeValue":function($event){_vm.questionBank.questionBankDescriptionAr = $event;
          _vm.questionBank.questionBankDescriptionEn = $event;}}}),_c('TextArea',{attrs:{"className":'col-md-12',"id":(_vm.id + "-questionBankNotes"),"value":_vm.questionBank.questionBankNotes,"title":_vm.$t('general.notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.questionBank.questionBankNotes = $event}}})],1),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg")}})]),_c('div',{staticClass:"icon-cancel",on:{"click":function($event){return _vm.$emit('close')}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }